import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslations from "./locales/en.json";
import esTranslations from "./locales/es.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslations
    },
    es: {
      translation: esTranslations
    }
  },
  lng: "es", // default language
  fallbackLng: "es",
  interpolation: {
    escapeValue: false
  },
  react: {
    useSuspense: false // This is important for immediate language switching
  }
});

export default i18n;
